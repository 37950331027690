<template>
  <div id="Sidebar-Wrapper">
    <div class="Sidebar">
      <div class="SidebarTop" :key="`SidebarWatcher` + windowIsMobile">
        <el-tooltip
          v-for="(topMI, tmindex) in getDesktopMenu.top"
          :key="tmindex"
          class="box-item"
          effect="customized"
          :content="topMI.name"
          :placement="usePlacementMethod"
        >
          <router-link
            :to="topMI.path"
            :class="{
              SidebarIcon: true,
              IconChoosed: getLocation === topMI.path,
            }"
            draggable="false"
            @click="
              () => {
                if (topMI.type === 'function') {
                  clickHandler(topMI.name);
                }
              }
            "
          >
            <img v-if="topMI?.iconClass == null" :src="topMI.iconIMG" alt="" />
            <i v-else :class="topMI.iconClass"></i>
          </router-link>
        </el-tooltip>
      </div>
      <div class="SidebarBottom">
        <el-tooltip
          v-for="(topMI, tmindex) in getDesktopMenu.bottom"
          :key="tmindex"
          class="box-item"
          effect="customized"
          :content="topMI.name"
          :placement="usePlacementMethod"
        >
          <router-link
            :to="topMI.path"
            :class="{
              SidebarIcon: true,
              IconChoosed: getLocation === topMI.path,
            }"
            draggable="false"
            @click="
              () => {
                if (topMI.type === 'function') {
                  clickHandler(topMI.name);
                }
              }
            "
          >
            <img v-if="topMI?.iconClass == null" :src="topMI.iconIMG" alt="" />
            <i v-else :class="topMI.iconClass"></i>
          </router-link>
        </el-tooltip>
      </div>

      <div class="MobileItems">
        <el-tooltip
          v-for="(topMI, tmindex) in getMobileMenu.menu"
          :key="tmindex"
          class="box-item"
          effect="customized"
          :content="topMI.name"
          :placement="usePlacementMethod"
        >
          <router-link
            :to="topMI.path"
            :class="{
              SidebarIcon: true,
              IconChoosed: getLocation === topMI.path,
            }"
            draggable="false"
            @click="
              () => {
                if (topMI.type === 'function') {
                  clickHandler(topMI.name);
                }
              }
            "
          >
            <img v-if="topMI?.iconClass == null" :src="topMI.iconIMG" alt="" />
            <i v-else :class="topMI.iconClass"></i>
          </router-link>
        </el-tooltip>

        <span
          v-if="getMobileMenu.add.length !== 0"
          :class="{ SidebarIcon: true, IconChoosed: openedMobileMenu }"
          draggable="false"
          @click="switchAddMenu(!openedMobileMenu)"
          :style="openedMobileMenu ? 'rotate: 90deg;' : ''"
        >
          <img src="./../../assets/img/sidebar_icons/burger_menu.svg" alt="" />
        </span>
      </div>
    </div>

    <div v-show="openedMobileMenu" class="MobileAddMenuWrapper">
      <div class="items">
        <router-link
          v-for="(topMI, tmindex) in getMobileMenu.add"
          :key="tmindex"
          class="item"
          :to="topMI.path"
          :class="{
            item: true,
            active: getLocation === topMI.path,
          }"
          draggable="false"
          @click="
            () => {
              if (topMI.type === 'function') {
                clickHandler(topMI.name);
              }
            }
          "
        >
          <img v-if="topMI?.iconClass == null" :src="topMI.iconIMG" alt="" />
          <i v-else :class="topMI.iconClass"></i>

          <span>{{ topMI.name }}</span>
        </router-link>
      </div>

      <div class="closeLayer" @click="switchAddMenu(false)"></div>
    </div>
  </div>
</template>

<style scoped>
#Sidebar-Wrapper {
  position: relative;
  display: flex;

  width: 82px;
  height: 100vh;

  margin: 0% 21px;
  padding: 23px 0% 23px 0%;

  flex-shrink: 0;
}
#Sidebar-Wrapper > .Sidebar {
  position: fixed;

  width: 82px;
  height: calc(100% - (23px * 2));

  margin: auto 0%;
  padding: 23px 0% 16px 0%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: #201e48;

  /* shadow */
  box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
  border-radius: var(--o-s-global-border-radius);

  overflow-x: hidden;
  overflow-y: auto;
}

#Sidebar-Wrapper > .Sidebar > div {
  position: relative;
  width: 36px;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 20px;

  margin: 0% auto;
}

#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon {
  position: relative;

  cursor: pointer;

  display: flex;
  justify-content: center;
  vertical-align: center;

  width: 36px;
  height: 36px;
}
#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon > i {
  position: relative;

  margin: auto;

  color: #656bff;
  font-size: 28px;
}

#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon:hover > img {
  opacity: 0.9;
}
#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon.IconChoosed > img,
#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon.IconChoosed > i {
  filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(39%)
    hue-rotate(201deg) brightness(111%) contrast(91%);
}

#Sidebar-Wrapper > .Sidebar > div > .SidebarIcon > img {
  position: relative;
  width: 24px;
  height: auto;
}

.MobileAddMenuWrapper {
  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: flex-end;

  width: 100%;
  height: calc(100svh - 100%);

  bottom: 100%;

  overflow: hidden;
}
.MobileAddMenuWrapper > .closeLayer {
  position: absolute;
  display: block;

  width: 100%;
  height: 100%;

  backdrop-filter: blur(2px);

  z-index: 1;
}
.MobileAddMenuWrapper > .items {
  position: relative;
  display: flex;

  padding: 20px 24px 8px 24px;

  width: 100%;
  height: fit-content;
  max-height: 100%;

  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;

  background-color: #27235c;

  border-radius: 12px 12px 0px 0px;

  overflow-x: hidden;
  overflow-y: auto;

  z-index: 2;
}
.MobileAddMenuWrapper > .items > .item > * {
  margin-top: 0%;
  margin-bottom: 0%;
}
.MobileAddMenuWrapper > .items > .item {
  position: relative;
  display: flex;

  cursor: pointer;

  padding: 12px 20px 12px 16px;

  width: 100%;
  height: fit-content;

  border-radius: 4px;

  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
}

.MobileAddMenuWrapper > .items > .item > img {
  position: relative;
  width: 24px;
  height: 24px;
}
.MobileAddMenuWrapper > .items > .item > i {
  position: relative;
  width: 24px;
  height: 24px;

  position: relative;

  color: #656bff;
  font-size: 24px;
}

.MobileAddMenuWrapper > .items > .item > span {
  position: relative;
  display: flex;

  width: fit-content;
  height: fit-content;

  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: #ffffffe5;
}

.MobileAddMenuWrapper > .items > .item.active {
  background-color: #656bff;
}

.MobileAddMenuWrapper > .items > .item.active > img,
.MobileAddMenuWrapper > .items > .item.active > i {
  filter: brightness(0) saturate(100%) invert(84%) sepia(11%) saturate(39%)
    hue-rotate(201deg) brightness(111%) contrast(91%);
}
</style>

<style scoped>
@media (max-width: 992px) {
  #Sidebar-Wrapper {
    position: fixed;

    width: 100%;
    height: 60px;

    margin: 0% !important;

    padding: 0%;

    bottom: 0;
    left: 0%;

    z-index: 1200;

    background: #201e48;

    box-shadow: 8px 0px 8px rgba(31, 18, 35, 0.15);
    border-radius: 0px;

    overflow: visible;
  }

  #Sidebar-Wrapper > .Sidebar > div.SidebarTop,
  #Sidebar-Wrapper > .Sidebar > div.SidebarBottom {
    display: none;
  }

  #Sidebar-Wrapper > .Sidebar {
    position: relative;

    width: 100%;
    height: fit-content;

    margin: auto;
    padding: 0% !important;
    gap: 0px;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    overflow: hidden;

    box-shadow: none;
  }
  #Sidebar-Wrapper > .Sidebar > div.MobileItems {
    width: 100%;
    height: fit-content;

    flex-direction: row;
    justify-content: space-between;

    margin: 0%;
    padding: 0% 24px;

    gap: 0;
  }
}
@media (min-width: 993px) and (max-width: 1600px) {
  #Sidebar-Wrapper > .Sidebar > div.MobileItems {
    display: none;
  }

  #Sidebar-Wrapper {
    width: calc(82px + (21px * 2));
    margin: 0%;
  }
  #Sidebar-Wrapper > .Sidebar {
    margin: auto 21px;
    gap: 20px;
  }
}
@media (min-width: 1600px) {
  #Sidebar-Wrapper > .Sidebar > div.MobileItems {
    display: none;
  }

  #Sidebar-Wrapper > .Sidebar {
    gap: 20px;
  }
}
</style>

<script>
import { logout } from "@/api/auth.js";
import { mapGetters } from "vuex";

export default {
  name: "Sidebar",
  props: {
    isUserSidebar: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    user: {
      handler(val) {
        if (this.id) {
          this.id = this.user.id;
        }
      },
      deep: true,
    },
  },
  components: {},
  computed: {
    ...mapGetters({
      user: "auth/isLoggedIn",
    }),
    getUserOrgType() {
      return this?.user?.parent_company[0]?.type;
    },
    usePlacementMethod() {
      if (this.windowIsMobile) {
        return "top-start";
      } else {
        return "right";
      }
    },

    getLocation() {
      let getLocation = window?.location?.pathname;

      if (
        getLocation != "" &&
        (getLocation === "/edu/about" ||
          getLocation.indexOf("/edu/about/") == 0 ||
          getLocation.indexOf("/edu/level/") == 0)
      ) {
        return "/edu/about";
      }

      return window?.location?.pathname;
    },

    getDesktopMenu() {
      let result = {
        top: [],
        bottom: [],
      };

      if (this.getUserOrgType == "") {
        return result;
      }

      let srcData = this.isUserSidebar
        ? this.userDesktopMenu
        : this.orgDesktopMenu;

      if (srcData.length === 0) {
        return result;
      }

      let addToTop = true;

      for (let item of srcData) {
        if (item === "---") {
          addToTop = !addToTop;
          continue;
        }

        let __item = this.menuItems[item];

        if (__item?.specificCheck === true) {
          if (this.specificCheck(item) === false) {
            continue;
          }
        }

        result[addToTop ? "top" : "bottom"].push(__item);
      }

      return result;
    },
    getMobileMenu() {
      let desktopData = this.isUserSidebar
        ? this.userDesktopMenu
        : this.orgDesktopMenu;

      let mobileData = this.isUserSidebar
        ? this.userMobileMenu
        : this.orgMobileMenu;

      let result = {
        menu: [],
        add: [],
      };

      if (this.getUserOrgType == "") {
        return result;
      }

      if (mobileData.length === 0) {
        return result;
      }

      result.menu = mobileData
        .map((item) => {
          let __item = this.menuItems[item];

          if (__item?.specificCheck === true) {
            if (this.specificCheck(item) === false) {
              return null;
            }
          }

          return this.menuItems[item];
        })
        .filter((x) => x != null);

      result.add = desktopData
        .filter((x) => x !== "---")
        .filter((item) => !mobileData.includes(item))
        .map((item) => {
          let __item = this.menuItems[item];

          if (__item?.specificCheck === true) {
            if (this.specificCheck(item) === false) {
              return null;
            }
          }

          return this.menuItems[item];
        })
        .filter((x) => x != null);

      return result;
    },
  },
  data() {
    return {
      windowIsMobile: false,

      menuItems: {
        user_profile: {
          type: "link",
          name: "Профиль",
          path: "/beta/profile",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/home.svg"),
        },
        user_achievements: {
          type: "link",
          name: "Достижения",
          path: "/beta/profile/achievements",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/students_rating.svg"),
        },
        user_education: {
          type: "link",
          name: "Обучение",
          path: "/edu/about",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/education.svg"),
        },
        user_store: {
          type: "link",
          name: "Магазин",
          path: "/beta/octa-store",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/octa_store.svg"),
        },
        user_inventory: {
          type: "link",
          name: "Инвентарь",
          path: "/beta/inventory",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/inventory.svg"),
        },
        user_profile_edit: {
          type: "link",
          name: "Редактирование профиля",
          path: "/profile/edit",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/profile_settings.svg"),
        },

        org_profile: {
          type: "link",
          name: "Профиль",
          path: "/beta/o/profile",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/home.svg"),
        },
        org_invites: {
          type: "link",
          name: "Пригласить организацию",
          path: "/beta/o/partner/invites",
          notifCounter: 0,
          iconIMG: "#",
          iconClass: "bx bx-plus-circle",
          specificCheck: true,
        },
        org_partners_list: {
          type: "link",
          name: "Ваш список организаций",
          path: "/beta/o/partners/list",
          notifCounter: 0,
          iconIMG: "#",
          iconClass: "bx bx-list-ul",
          specificCheck: true,
        },
        org_students_rating: {
          type: "link",
          name: "Рейтинг студентов",
          path: "/beta/o/studentsList",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/students_rating.svg"),
        },

        general_universities: {
          type: "link",
          name: "Учебные заведения",
          path: "/universities",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/vuzes.svg"),
        },
        general_faq_page: {
          type: "link",
          name: "Часто задаваемые вопросы",
          path: "/beta/faq-page",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/question.svg"),
        },
        general_logout: {
          type: "function",
          name: "Выход",
          path: "#",
          notifCounter: 0,
          iconIMG: require("./../../assets/img/sidebar_icons/logout.svg"),
        },
      },

      userDesktopMenu: [
        "user_profile",
        "user_achievements",
        "user_education",
        "general_universities",
        "user_store",
        "user_inventory",
        "user_profile_edit",
        "---", // Разделитель верхнего и нижнего меню
        "general_faq_page",
        "general_logout",
      ],
      orgDesktopMenu: [
        "org_profile",
        "org_invites",
        "org_partners_list",
        "general_universities",
        "org_students_rating",
        "---",
        "general_logout",
      ],

      //Может содержать не более 4х пунктов. Выбранные пункты показываются на мобилке, всё остальное - уходит в доп. меню
      userMobileMenu: [
        "user_education",
        "user_achievements",
        "user_profile",
        "general_universities",
      ],
      orgMobileMenu: [
        "general_universities",
        "org_students_rating",
        "org_profile",
        "general_logout",
      ],

      openedMobileMenu: false,
    };
  },
  methods: {
    logout() {
      try {
        logout();
        this.$store.dispatch("auth/logout");
        this.$router.push("/");
      } catch (error) {
        this.$router.push("/");
      }
    },

    checkMobileState() {
      if (window.innerWidth <= 992) {
        this.windowIsMobile = true;
      } else {
        this.windowIsMobile = false;
      }
    },

    clickHandler(menuItemName) {
      switch (menuItemName) {
        case "Выход": {
          this.logout();
          break;
        }
      }
    },

    switchAddMenu(state) {
      let askButton = document.querySelector(
        'a[href="https://t.me/octagon_help_bot"]'
      );

      if (askButton) {
        askButton.style.display = state ? "none" : "block";
      }

      this.openedMobileMenu = state;
    },

    specificCheck(menuItemName) {
      switch (menuItemName) {
        case "org_invites":
        case "org_partners_list": {
          if (this.getUserOrgType === "Партнер") {
            return true;
          }
          break;
        }
      }

      return false;
    },
  },
  created() {
    this.checkMobileState();
    window.addEventListener(`resize`, this.checkMobileState, false);
  },
};
</script>
